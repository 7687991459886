import website from '../assets/website.png'
// import instagram from '../assets/instagram.png'
// import appstore from '../assets/playstore.png'
// import youtube from '../assets/youtube.jpeg'
// import dribbble from '../assets/dribbble.png'
// import github from '../assets/github.png'
// import telegram from '../assets/telegram.png'
// import linkedin from '../assets/linkedin.png'
// import photography from '../assets/photography.png'
// import personallogo from '../assets/personalsite.png'
// import twitter from '../assets/twitter.png'
// import graphsketchpad from '../assets/graphsketchpad.png'
// import villagemealgenerator from '../assets/villagemealgenerator.png'

const items = [
{
    "title": "Gitkraken",
    "subtitle": "Install GitKraken!",
    "image": website,
    "link": "http://gitkraken.benjamin.michaelis.net"
},
{
    "title": "Wealthfront Investment Account",
    "subtitle": "Get 5k managed for free!",
    "image": website,
    "link": "http://wealthfrontinvest.benjamin.michaelis.net"
},
{
    "title": "Wealthfront Cash Account",
    "subtitle": "Get a .5% boost in your cash account!",
    "image": website,
    "link": "http://wealthfrontcash.benjamin.michaelis.net"
},
{
    "title": "Discover Card",
    "subtitle": "Get a $100 credit on your discover card!",
    "image": website,
    "link": "http://discover.benjamin.michaelis.net"
},
    {
    "title": "Capital One SavorOne Card",
    "subtitle": "3% cash back on dining, entertainment, streaming services and non-superstore grocery stores.",
    "image": website,
    "link": "http://savorone.benjamin.michaelis.net"
},
]

export default items
